import React from 'react'
import {  ToastContainer } from 'react-toastify'

const Notification = () => {
 
  return (
    <ToastContainer />
  )
}

export default React.memo(Notification)